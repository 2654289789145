<template>
    <div class="magazin">

		<LandingpageHeader activeTab="magazine"></LandingpageHeader>

        <!-- HEADER -->

        <div class="landingpage__header">

            <div class="landingpage__header__text">
                <h1>Verbessere dein Gameplay mit unserem <span>Magazin</span>!</h1>
                <p>Interessante Artikel, Rezepte, Hacks u.v.m. rund um die Themen Training &amp; Ernährung. Erweitere deinen Horizont.</p>
            </div>

            <div class="landingpage__header__image">
                <img src="@/assets/landingpage/magazin-header.jpg" srcset="@/assets/landingpage/magazin-header@2x.jpg 2x" alt="Magazin SportlerPlus">
            </div>

        </div>

        <div class="magazin__content">

            <div class="magazin__filter">
                <div class="magazin__filter__search">
                    <input v-model="searchWord" v-on:input="startSearch" type="text" placeholder="Artikel suchen">
                    <div class="magazin__filter__search__icon"></div>
                    <div class="magazin__filter__search__close" @click="dismissSearch"></div>
                </div>
                <div @click="openCategoryFilter" ref="categoryFilter" class="magazin__filter__item">
                    <img src="@/assets/ic_16_filter.svg" alt="Thema filtern">
                </div>
            </div>

            <div class="magazin__list">
                <div v-for="(article, i) in paginate(filteredArticles)" :key="i" class="magazin__item noselect flex_item">
                    <span @click="handleArticleClick(article)">
                    <img :src="'https://app.sportlerplus.com/scaled/800/' + article.main_image_url" class="magazin__item__image">
                    <div v-if="article.series_subtitle" class="magazin__item__badge">{{ article.series_subtitle }}</div>
                    <div v-else class="magazin__item__badge">{{ article.media_type_names }}</div>
                    <div class="magazin__item__text">
                        <div class="magazin__item__title">{{ article.title }}</div>
                        <div class="magazin__item__copy">{{ article.intro }}</div>
                        <div class="magazin__item__meta">{{ articleReadTime(article) }}</div>
                    </div>
                    </span>
                </div>
            </div>

            <div class="center">
                <div class="pagination">
                    <a @click="prevPage()" class="pagination__prev">
                        <img src="@/assets/ic_nav_back.svg" alt="Vorherige Seite">
                    </a>
                    <a @click="currentPage = p" v-for="p in pageCount()" v-bind:key="p" :class="p == currentPage ? 'active' : ''">{{ p }}</a>
                    <a @click="nextPage()" class="pagination__next">
                        <img src="@/assets/ic_nav_next.svg" alt="Nächste Seite">
                    </a>
                </div>
            </div>
        </div>

        <LandingpageFooter></LandingpageFooter>

        <!-- Filter Modal "Thema" -->
        <VModal @closed="applyCategoryFilter" name="categoryFilter" width="100%" height="auto" :shiftY="1" :scrollable="true">

        <div class="vm-header">
            <div class="vm-header-left">
            <img @click="resetCategorySelection" v-show="categorySelectionChanged" src="@/assets/ic_nav_reset.svg" alt="">
            </div>
            <div class="vm-header-center">
            <span>Thema/Themen wählen</span>
            </div>
            <div class="vm-header-right">
            <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissCategoryFilter">
            </div>
        </div>

        <div class="vm-content">

            <div>
            <div @click="toggleCategorySelect(category)" :class="category.selected ? 'list__item large selected' : 'list__item large'" v-for="category in articleCategories" :key="category.id">
                <div class="list__item__icon">
                <img v-if="category.name=='Training' && category.selected" src="@/assets/topic/ic_topic_training-active.svg" alt="">
                <img v-if="category.name=='Training' && !category.selected" src="@/assets/topic/ic_topic_training.svg" alt="">
                <img v-if="category.name=='Ernährung' && category.selected" src="@/assets/topic/ic_topic_food-active.svg" alt="">
                <img v-if="category.name=='Ernährung' && !category.selected" src="@/assets/topic/ic_topic_food.svg" alt="">
                <img v-if="category.name=='Rezepte' && category.selected" src="@/assets/topic/ic_topic_recipe-active.svg" alt="">
                <img v-if="category.name=='Rezepte' && !category.selected" src="@/assets/topic/ic_topic_recipe.svg" alt="">
                <img v-if="category.name=='Trends' && category.selected" src="@/assets/topic/ic_topic_trends-active.svg" alt="">
                <img v-if="category.name=='Trends' && !category.selected" src="@/assets/topic/ic_topic_trends.svg" alt="">
                <img v-if="category.name=='Produkte & Supplements' && category.selected" src="@/assets/topic/ic_topic_products-active.svg" alt="">
                <img v-if="category.name=='Produkte & Supplements' && !category.selected" src="@/assets/topic/ic_topic_products.svg" alt="">
                <img v-if="category.name=='News' && category.selected" src="@/assets/topic/ic_topic_news-active.svg" alt="">
                <img v-if="category.name=='News' && !category.selected" src="@/assets/topic/ic_topic_news.svg" alt="">
                <img v-if="category.name=='Podcast' && category.selected" src="@/assets/medium/ic_medium_podcast-active.svg" alt="">
                <img v-if="category.name=='Podcast' && !category.selected" src="@/assets/medium/ic_medium_podcast.svg" alt="">
                <img v-if="category.name=='Interview' && category.selected" src="@/assets/medium/ic_medium_interview-active.svg" alt="">
                <img v-if="category.name=='Interview' && !category.selected" src="@/assets/medium/ic_medium_interview.svg" alt="">
                </div>
                <div class="list__item__content">
                <div class="list__item__label">
                    {{ category.name }}
                </div>
                </div>
            </div>
            </div>

            <v-btn @click="applyCategoryFilter" class="button button--primary filterButton">Auswahl bestätigen</v-btn>

        </div>

        </VModal>

    </div>
</template>

<script>
import LandingpageHeader from "@/components/LandingpageHeader.vue";
import LandingpageFooter from "@/components/LandingpageFooter.vue";
import {analytics} from '@/firebase/index.js';

export default {
    name: 'BrandpageMagazine',
    components: { LandingpageHeader, LandingpageFooter },
    metaInfo() {
        if (this.page == null) { return {meta: []} }
		return { 
            title: this.page.title,
			meta: [
                {
                    vmid: "description",
                    property: "description",
                    content: "Das SportlerPlus Magazin liefert spannende Artikel zu den Themen Fitness & Ernährung.",
                },
				{
                    vmid: "og:title",
					property: "og:title",
					content: this.page.title
				},
				{
                    vmid: "og:site_name",
					property: "og:site_name",
					content: "SportlerPlus"
				},
				{
                    vmid: "og:type",
					property: "og:type",
					content: "website"
				}
			]
		}
	},
    methods: { 
        init() {
            this.$store.dispatch('fetchArticles').then(() => {
                this.articles = this.$store.getters.getArticles;
                this.$store.dispatch('fetchArticleCategories').then(() => {
                    this.articleCategories = this.$store.getters.getArticleCategories;
                    this.applyCategoryFilter();
                });
            });
            analytics.logEvent('view_article_overview_brandpage');

            if (window.localStorage.getItem('searchWord') !== null) {
                this.searchWord = window.localStorage.getItem('searchWord');
                this.startSearch();
            }
        },
        paginate(list) {
            let startIdx = (this.currentPage - 1) * this.itemsPerPage;
            let endIdx = startIdx + this.itemsPerPage;
            return list.slice(startIdx, endIdx);
        },
        pageCount() {
            if (!this.filteredArticles) { return 0; }
            return Math.ceil(this.filteredArticles.length / this.itemsPerPage);
        },
        prevPage() {
            this.currentPage = Math.max(1, this.currentPage - 1);
        },
        nextPage() {
            this.currentPage = Math.min(this.pageCount(), this.currentPage + 1);
        },
        articleReadTime(article) {
            if (this.readArticleList.indexOf(article.id) != -1) {
                return "Bereits gelesen";
            }
            return article.readtime + " Min.";
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                /* TODO: Smooth Transition doesn't work */
                behavior: 'smooth'
            });
        },
        openTypeFilter() {
            this.mediaTypeSelectionChanged = this.selectedMediaTypes.length > 0;
            this.previousSelectedMediaTypes = JSON.parse(JSON.stringify(this.mediaTypes));
            this.$modal.show("typeFilter");
        },
        openCategoryFilter() {
            this.categorySelectionChanged = this.selectedCategories.length > 0;
            this.previousSelectedCategories = JSON.parse(JSON.stringify(this.articleCategories));
            this.$modal.show("categoryFilter");
        },
        toggleMediaTypeSelect(mt) {
            mt.selected = !mt.selected;
            if (this.mediaTypes.find(mt => mt.selected)) {
              this.mediaTypeSelectionChanged = true;
            }
            else {
              this.mediaTypeSelectionChanged = false;
            }
            this.$forceUpdate();
        },
        toggleCategorySelect(cat) {
            cat.selected = !cat.selected;
            if (this.articleCategories.find(ac => ac.selected)) {
              this.categorySelectionChanged = true;
            }
            else {
              this.categorySelectionChanged = false;
            }
            this.$forceUpdate();
        },
        resetCategorySelection() {
            this.categorySelectionChanged = false;
            this.articleCategories.forEach( ac => ac.selected = false );
            this.currentPage = 1;
            this.$forceUpdate();
        },
        resetMediaTypeSelection() {
            this.mediaTypeSelectionChanged = false;
            this.mediaTypes.forEach( mt => mt.selected = false );
            this.currentPage = 1;
            this.$forceUpdate();
        },
        applyCategoryFilter() {
            this.$modal.hide('categoryFilter');
            this.currentPage = 1;
            this.selectedCategories = this.articleCategories.filter( ac => ac.selected );
            this.selectedCategoryNames = this.selectedCategories.map( ac => ac.name).join(", ");
            if (this.selectedCategoryNames.length == 0) {
                this.selectedCategoryNames = "Kategorien wählen";
            }
            if (this.selectedCategories.length == 0) {
                this.$refs['categoryFilter'].classList.remove("magazin__filter__item--active");
            }
            else {
                this.$refs['categoryFilter'].classList.add("magazin__filter__item--active");
            }
        },
        async startSearch() {
            if (this.searchWord === "") {
                this.articles = this.getArticles;
            }
            else if (this.searchWord && this.searchWord.length >= 3) {
                await this.$store.dispatch('searchArticlesByInput', {search_word: this.searchWord}).then((res) => {
                    this.articles = res.items;
                });
            }
        },
        dismissSearch() {
            this.articles = this.getArticles;
            window.localStorage.removeItem('searchWord');
            this.searchWord = "";
            this.selectedCategories.forEach(art => art.selected = false);
            this.selectedCategories = [];
            this.$refs['categoryFilter'].classList.remove("magazin__filter__item--active");
        },
        dismissCategoryFilter() {
            this.$modal.hide('categoryFilter');
            this.articleCategories = JSON.parse(JSON.stringify(this.previousSelectedCategories));
        },
        handleArticleClick(article) {
            window.localStorage.setItem('searchWord', this.searchWord);
            this.$router.push("/articles/" + article.meta.slug);
        },
        handleFeatureClick(feature) {
            // features are pre-filtered (only type blog.BlogPage)
            let article = this.getArticles.find(a => a.id == feature.feature.id);
            if (article) this.handleArticleClick(article);
        }
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            articleCategories: [],
            mediaTypes: [],
            categorySelectionChanged: false,
            mediaTypeSelectionChanged: false,
            selectedMediaTypes: [],
            previousSelectedMediaTypes: [],
            selectedCategories: [],
            previousSelectedCategories: [],
            selectedMediaTypeNames: "Medium",
            selectedCategoryNames: "Kategorien wählen",
            readArticleList: [],
            currentPage: 1,
            itemsPerPage: 12,
            searchWord: "",
            articles: [],
        }
    },
    computed: {
        getArticles() {
            return this.$store.getters.getArticles;
        },
        filteredArticles() {
            let searchCategories = this.selectedCategories.map(c => c.id);

            let filtered = [];
            if (!this.articles) { return filtered; }

            this.articles.forEach(function(el) {
                let categoryMatch = false;

                if (searchCategories.length === 0) {
                    categoryMatch = true;
                }
                else {
                    let currentCategories = el.categories.map(ac => ac.id);
                    if (currentCategories.filter(ac => searchCategories.includes(ac)).length > 0) {
                        categoryMatch = true;
                    }
                }

                if (categoryMatch) {
                    filtered.push(el);
                }
            });
            return filtered;
        }
    }
}
</script>

<style lang="scss">
    .magazin {
        position: relative;
        overflow: hidden;

        nav {
            z-index: 2;

            @media screen and (min-width: 768px) {
                background: none;
                position: relative;
            }
        }
    }
</style>

<style scoped lang="scss">
    .magazin {

        &__content {
            position: relative;
            z-index: 2;
            max-width: 1088px;
            margin: 0px auto 96px;
            padding: 0 16px;

            @media screen and (min-width: 768px) {
                padding: 0 24px;
                margin-bottom: 160px;
            }
        }

        &__filter {
            padding-top: 0;
            padding-bottom: 8px;

            @media screen and (min-width: 768px) {
                align-items: center;
                padding-bottom: 24px;
                justify-content: flex-end;

                &__item {
                    max-width: 200px;

                    &:not(:last-child) {
                        margin-right: 16px;
                    }
                }
            }

            &__title {
                font-size: 14px;
                font-weight: 600;
                color: $colorBlack;
                margin-right: auto;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (min-width: 768px) {
                &:after {
                    content: '';
                    flex: auto;
                }
            }
        }

        &__item {
            width: 100%;
            border: 1px solid rgba($colorBlack, .08);

            @media screen and (min-width: 768px) {
                width: calc((100% - 48px) / 3);
                margin-bottom: 24px;

                &:nth-child(3n+1) {
                    margin-right: 24px;
                }

                &:nth-child(3n+2) {
                    margin-right: 24px;
                }
            }
        }
    }

    .pagination {
        display: flex;
        width: 100%;
        margin: 32px 0 80px;

        @media screen and (min-width: 768px) {
            justify-content: center;
        }

        a {
            display: flex;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: rgba($colorBlack, .48);
            padding: 6px 14px;
            text-decoration: none;
            margin: 0 2px;
            border-radius: 4px;
            transition: all .2s ease-in-out;

            &:hover:not(.active) {
                background: rgba($colorBlack, .08);
            }

            &.active {
                background-color: $colorPrimary;
                color: $colorWhite;
            }
        }

        &__prev {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: auto !important;
            margin-left: 0 !important;
            padding: 0 4px !important;
            opacity: .48;

            @media screen and (min-width: 768px) {
                margin-right: 16px !important;
            }

            &:hover {
                background: none !important;
                opacity: .64;
            }
        }

        &__next {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto !important;
            margin-right: 0 !important;
            padding: 0 4px !important;
            opacity: .48;

            @media screen and (min-width: 768px) {
                margin-left: 16px !important;
            }

            &:hover {
                background: none !important;
                opacity: .64;
            }
        }
    }
</style>

